import { updateField } from 'vuex-map-fields'

export default {
  updateField,
  setInstitution (state, data) {
    state.institution = data
  },
  setSuccess (state, data) {
    state.institution = data.institution
    state.token = data.token
    state.permissions = data.permissions
    state.loggedIn = true
  },
  logout (state) {
    state.permissions = []
    state.user = { name: 'Não autenticado' }
    state.loggedIn = false
    state.token = null
  },
  setTemporarySession (state, data) {
    state.temporarySession.token = data.token
    state.temporarySession.permissions = data.permissions
  }
}
