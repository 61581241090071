import { getField, updateField } from 'vuex-map-fields'
import dynamicComponentsApi from '../../../api/dynamicComponents'

export const state = () => ({
  dynamicComponents: []
})

export const getters = {
  getField
}

export const actions = {
  async listGroups ({ commit, dispatch, state, rootState }, path) {
    return dynamicComponentsApi.getComponentGroups(path)
  },
  async list ({ commit, dispatch, state, rootState }) {
    return dynamicComponentsApi.getComponentsMetaData()
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
