import { getField, updateField } from 'vuex-map-fields'
import ChatApi from '../../../api/chat'
import firebase from 'firebase'
import Firebase from '../../firebase'

const firestore = Firebase.firestore

export const state = () => ({
  chatsList: [],
  messagesList: [],
  imageDialog: false,
  imageMessage: null,
  selectedChatId: null
})

export const getters = {
  getField,
  getChatsList (state) {
    return state.chatsList
  },
  getMessagesList (state) {
    return state.messagesList
  },
  getSelectedChatId (state) {
    return state.selectedChatId
  }
}

export const actions = {
  async setLastMoment ({ commit, state }, chat) {
    try {
      const chatUpdated = await ChatApi.setLastMoment(chat)

      for (let room of state.chatsList) {
        if (room.id === chat.id) {
          room.updated_time = chatUpdated.updated_time
        }
      }

      commit('orderChatList')
    } catch (err) {
      console.error(err)
    }
  },
  async list ({ commit, dispatch, state, rootState }) {
    const chatsList = await ChatApi.listChats()
    commit('updateChats', chatsList.rooms)
    return chatsList
  },
  async listMessages ({ commit, dispatch, state, rootState }) {
    if (state.selectedChatId) {
      const messagesList = await ChatApi.listMessages(state.selectedChatId)
      commit('updateMessages', messagesList)
      return messagesList
    } else {
      return null
    }
  },
  async showImage ({ commit, dispatch, state, rootState }, message) {
    commit('showImage', message)
  },
  async appendMessage ({ commit, dispatch, state, rootState }, message) {
    commit('appendMessage', message)
  },
  async prependChat ({ commit, dispatch, state, rootState }, payload) {
    commit('unshiftChat', payload)
  },
  async updateSelectedChat ({ commit }, payload) {
    commit('updateSelectedChat', payload)
  },

  /**
   * @description Creates a room with a title or returns if already created
   * @param {Object} participants
   */
  async createTitledRoom ({ commit }, assocs) {
    const room = await ChatApi.createTitledRoom(assocs)
    return room
  },

  /**
   * @description Create message for a room in firestore
   * @param {Object} data
   * @param {String} data.organization Organization name
   * @param {Object} data.room Room to send message
   * @param {String} data.room.id Room id to send message
   * @param {Object} data.author Author of message
   * @param {Integer} data.author.id Message's author id
   * @param {String} data.author.name Message's author name
   * @param {String} data.text Message's text
   */
  async sendMessage ({ commit }, data) {
    await firestore.collection(`chat/${data.organization}/conversations/${data.room.id}/messages`).add({
      text: data.text,
      author: data.author,
      created_at: firebase.firestore.FieldValue.serverTimestamp()
    })
  },
  async createRoom ({ commit }, data) {
    console.log('Creating room...', data)
    const entities = data
    const members = {
      users: [],
      groups: []
    }

    for (const entity of entities) {
      if (entity.entityType === 'user') {
        members.users.push(entity)
      } else if (entity.entityType === 'group') {
        members.groups.push(entity)
      }
    }

    const Room = await ChatApi.createRoom(members)

    return Room
  }
}

export const mutations = {
  updateField,
  updateChats (state, data) {
    state.chatsList = data
  },
  updateMessages (state, data) {
    state.messagesList = data
  },
  showImage (state, data) {
    state.imageMessage = data
    state.imageDialog = true
  },
  appendMessage (state, data) {
    state.messagesList.messages.push(data)
  },
  unshiftChat (state, data) {
    state.chatsList.unshift(data)
  },
  updateSelectedChat (state, data) {
    state.selectedChatId = data
  },
  orderChatList (state) {
    state.chatsList = state.chatsList.sort((a, b) => {
      const aTime = new Date(a.updated_time)
      const bTime = new Date(b.updated_time)

      return bTime.getTime() - aTime.getTime()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
