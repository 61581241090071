import { getField, updateField } from 'vuex-map-fields'

/**
 *
 *
 * This store will be used only to save current post editing on localstorage...
 *
 *
 */
export const state = () => ({
  calledEdit: 0,
  postForEdit: {
    title: '',
    body: '',
    time: '',
    date: '',
    mediaURL: [],
    images: [],
    category_id: ''
  }
})

export const getters = {
  getField,
  currentPost (state) {
    return state.postForEdit
  }
}

export const actions = {

  postForEdit ({
    commit
  }, post) {
    commit('postForEdit', post)
  }

}

export const mutations = {
  updateField,
  postForEdit (state, post) {
    state.postForEdit = post
    state.calledEdit++
  },
  postClean (state) {
    state.postForEdit = {
      title: '',
      body: '',
      time: '',
      date: '',
      mediaURL: [],
      medias: [],
      images: [],
      category_id: '',
      to: []
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
