import store from '../store/index'

import io from 'socket.io-client'

const socketServerUrl = 'https://chat.agendaconectada.com.br'

export function createSocket () {
  var socket = io(socketServerUrl, {
    path: '/socket.io/',
    transports: ['websocket']
  })
  socket.on('connect', function () {
    socket.emit('authenticate', {
      app: store.state.auth.institution, // REMOVE legacy
      token: store.getters.token
    }, function (res) {
      if (!res || res.error) {} else {}
    })
  })
  socket.on('disconnect', function () {})
  socket.on('message', function (payload) {
    console.log('INCOMING MESSAGE', payload)
    if (payload === 'ChatMessageUpdate') {
      // Nova mensagem. atualize a listagem de mensagens não lidas..
      // store.dispatch('chat/list')
      // Don't needed by firestore.
      store.dispatch('chat/updateConversationStats')
    } else if (payload === 'ChatConversationUpdate') {
      // Convesas atualizadas.
      store.dispatch('chat/list')
      store.dispatch('app/showSnackbar', 'Uma nova conversa na ouvidoria')
    }
  })

  // REMOVE legacy
  socket.on('authenticate', function (data) {
    if (!data || data.error) {} else {}
  })
}
