import { getField, updateField } from 'vuex-map-fields'
import organizationGroups from '../../../api/organizationgroups'

export const state = () => ({
  neverListed: true,
  groups: [],
  groupsChildren: {
    filiais: [],
    ensinos: [],
    anos: [],
    turmas: []
  },
  users: [],
  types: []
})

export const getters = {
  getField,
  getGroups (state) {
    return state.groups
  },
  getGroupsChildren (state) {
    return state.groupsChildren
  },
  getUsers (state) {
    return state.users
  }
}

export const actions = {
  async list ({ commit, dispatch, state, rootState }) {
    if (state.neverListed) {
      const groups = await organizationGroups.list()
      const groupsChildren = {
        filiais: groups.filter(t => t.type === 'filial'),
        ensinos: groups.filter(t => t.type === 'ensino'),
        anos: groups.filter(t => t.type === 'ano'),
        turmas: groups.filter(t => t.type === 'turma')
      }
      commit('update', { groups, groupsChildren })
      commit('setNeverListed', false)
      return groups
    } else {
      return state.groups
    }
  },

  async peopleList ({ commit, dispatch, state, rootState }, params) {
    const people = await organizationGroups.listAssocs(params)
    return people
  },

  async listTypes ({ commit, dispatch, state, rootState }) {
    const types = await organizationGroups.listTypes()
    commit('setTypes', types)
  }
}

export const mutations = {
  updateField,
  update (state, data) {
    state.groups = data.groups
    state.groupsChildren = data.groupsChildren
    state.users = data.alunos
  },

  setTypes (state, data) {
    state.types = data
  },

  setNeverListed (state, data) {
    state.neverListed = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
