import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  drawer: false,
  showingDrawer: true,
  hasFAB: false,
  showFAB: false,
  requestedPath: '/',
  search: '',
  selectedGroups: [],
  snackbarEnable: false,
  snackbarText: ''
})

export const getters = {
  getField,
  drawer (state) {
    return state.drawer
  },
  showingDrawer (state) {
    return state.showingDrawer
  },
  hasFAB (state) {
    return state.hasFAB
  },
  showFAB (state) {
    return state.showFAB
  },
  requestedPath (state) {
    return state.requestedPath
  },
  getSearchField (state) {
    return state.search
  },
  getSelectedGroupsField (state) {
    return state.selectedGroups
  }
}

export const actions = {
  async showSnackbar ({ commit, dispatch, state, rootState }, payload) {
    commit('updateSnackbarEnable', true)
    commit('updateSnackbarText', payload)
    setTimeout(() => {
      commit('updateSnackbarEnable', false)
      commit('updateSnackbarText', '')
    }, 6000)
    return true
  },
  async closeSnackbar ({ commit, dispatch, state, rootState }) {
    commit('updateSnackbarEnable', false)
    commit('updateSnackbarText', '')
    return true
  }
}

export const mutations = {
  updateField,
  drawerToogle (state, data) {
    state.drawer = !state.drawer
  },
  showDrawer (state, data) {
    state.showingDrawer = data
  },
  hasFab (state, data) {
    state.hasFAB = data
  },
  updateShowFab (state, data) {
    state.showFAB = data
  },
  setUrlRequestedPath (state, data) {
    state.requestedPath = data
  },
  updateSearchField (state, data) {
    state.search = data
  },
  updateSelectedGroupsField (state, data) {
    state.selectedGroups = data
  },
  updateSnackbarEnable (state, data) {
    state.snackbarEnable = data
  },
  updateSnackbarText (state, data) {
    state.snackbarText = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
