import usersApi from '../../../api/user'

export default {
  async list ({ commit, dispatch, state, rootState }) {
    // const res = await user.getUserType('aluno')
    const users = await usersApi.list({ types: [] })
    const usersChildren = {
      alunos: users.filter(t => t.type === 'aluno'),
      parents: users.filter(t => t.type === 'parent'),
      profs: users.filter(t => t.type === 'prof'),
      profsSupervised: users.filter(t => t.type === 'prof_supervised'),
      admins: users.filter(t => t.type === 'admin')
    }
    commit('setUsers', { users, usersChildren })
  },
  async listTypes ({ commit, dispatch, state, rootState }) {
    const types = await usersApi.listTypes()
    commit('setTypes', types)
  },
  async listProfiles ({ commit }) {
    const profiles = await usersApi.listProfiles()
    commit('setProfiles', profiles)
  },
  /**
   * @description Search users
   * @param {Object} params
   * @param {Array} params.types Users types included
   * @param {Integer} params.next Next user id in pagination
   * @param {Integer} params.offset Users offset to get
   * @param {String} params.search Search string
   */
  async search ({ commit }, params) {
    const list = await usersApi.list(params)
    return list
  },
  async save ({ commit }, data) {
    const createdUser = await usersApi.saveUser(data)
    commit('save', createdUser)

    return createdUser
  }
}
