export default {
  usersChildren: {
    admins: [],
    profs: [],
    alunos: [],
    parents: [],
    profsSupervised: []
  },
  users: [],
  types: [],
  profiles: []
}
