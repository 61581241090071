import dynamicComponentsApi from '../api/dynamicComponents'
import Grades from '../api/grades' // eslint-disable-line
import Catraca from '../api/catraca'
import Post from '../api/posts'
import config from '../api/api.config'
import Notifications from '../api/notification'
import Organization from '../api/organization'
import Presence from '../api/presence'
import User from '../api/user'
import firebase from '../store/firebase'
import juice from 'juice'
const Firestore = firebase.firestore

const compiler = require('vue-template-compiler')

const getEndingObjectIndex = (starting, componentData) => {
  let open = 0
  const chars = componentData.split('')

  for (let i = starting; i < chars.length; i++) {
    if (chars[i] === '{') open++
    else if (chars[i] === '}') open--
    if (open === 0) {
      return i
    }
  }
}

export default async function (path) {
  // Get component Data
  /* eslint-disable */
  let componentData = ''
  try {
    const url = await dynamicComponentsApi.getUrl(path)
    // date=new Date() to avoid cache
    componentData = await dynamicComponentsApi.getComponentData(`${url}`)
  } catch (err) {
    console.error(err)
    throw err
  }

  componentData = juice(componentData)

  // Parse template
  const startingTemplateMatch = '<template>'
  const endingTemplateMatch = '</template>'
  const startingTemplateIndex = componentData.indexOf(startingTemplateMatch)
  const endingTemplateIndex = componentData.lastIndexOf(endingTemplateMatch)
  const template = componentData.substring(startingTemplateIndex + startingTemplateMatch.length, endingTemplateIndex)

  // Parse object
  const exportMatch = 'export default '
  const exportIndex = componentData.indexOf(exportMatch)
  const startingObjectIndex = exportIndex + exportMatch.length
  const endingObjectIndex = getEndingObjectIndex(startingObjectIndex, componentData)
  const objectData = componentData.substring(startingObjectIndex, endingObjectIndex + 1)

  // External Component Scope
  const axios = require('axios')
  const moment = require('moment')  
  const Quoti = {
    axios: config.axios,
    moment: moment,
    firestoreData: Firestore.collection('/dynamicComponents/1mYEpvSJrk1Myzdxkh4A/data/').doc('collections'),
    organizationApi: Organization,
    userApi: User, 
    presenceApi: Presence,
    gradesApi: Grades,
    catracaApi: Catraca,
    postApi: Post,
    notificationsApi: Notifications
  }
  

  // Evaluate component
  const object = eval(`(function () {return ${objectData}})()`) // eslint-disable-line

  // Compile template
  object.render = compiler.compileToFunctions(template).render
  object.staticRenderFns = compiler.compileToFunctions(template).staticRenderFns

  return object
}
