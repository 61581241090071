import { updateField } from 'vuex-map-fields'

export default {
  updateField,
  setUsers (state, data) {
    state.users = data.users
    state.usersChildren = data.usersChildren
  },
  setTypes (state, data) {
    state.types = data
  },
  setProfiles (state, data) {
    state.profiles = data
  },
  save (state, data) {
    const user = state.users.find(u => u.id === data.id)

    if (user) {
      Object.assign(user, data)
    } else {
      state.users.push(data)
    }
  }
}
