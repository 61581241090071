import usersApi from '../../../api/user'
import postsApi from '../../../api/posts'

export default {
  async getMe ({ commit, dispatch, state, rootState }) {
    const me = await usersApi.getMe()
    commit('setMe', me)
  },
  async get ({ commit, dispatch, state, rootState }, payload) {
    const user = await usersApi.getUser(payload.userId)
    return user
  },
  async getPosts ({ commit, dispatch, state, rootState }) {
    const posts = await postsApi.listLastPosts(state.me.id)
    commit('setPosts', posts)
  },
  async updateProfileImage ({ commit }, data) {
    // Update database
    await usersApi.updateProfileImage(data.imageUrl)

    // Commit changes
    commit('updateProfileImage', {
      ...data
    })
  }
}
