import diary from '../../../api/diary'

export default {
  async listRecipientsGroups ({ commit, dispatch, state, rootState }, payload) {
    const diaryList = await diary.listRecipientsGroups(payload)
    commit('update', diaryList)
    return diaryList
  },

  async listRecipientsUsers ({ commit, dispatch, state, rootState }, payload) {
    const diaryList = await diary.listRecipientsUsers(payload)
    commit('update', diaryList)
    return diaryList
  }
}
