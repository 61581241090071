import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  infos: {
    slug: '',
    name: '',
    year: '',
    image: {
      logo: '',
      login: '',
      900: ''
    }
  },
  themes: {
  }
})

export const getters = {
  getField
}

export const actions = {
}

export const mutations = {
  updateField,
  set (state, data) {
    // trying salve all org info
    state.infos = data.infos
    state.themes = data.theme.themes
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
