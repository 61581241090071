import moment from 'moment-timezone'
import 'moment/locale/pt-br'
import axios from 'axios'

export default new class {
  constructor () {
    this._promisse = new Promise(async (resolve) => {
      try {
        console.log('MOMENT LOCAL TIME', moment().format('YYYY-mm-DD HH:mm:ss.SSSSZ'))

        const relativeTimeInfoRes = await axios.get('https://worldtimeapi.org/api/timezone/America/Recife', {
          timeout: 3000
        })
        const relativeTimeInfo = relativeTimeInfoRes.data
        const baseDeltaTime = (new Date()).getTime()

        moment.now = function () {
          const relativeTime = (new Date(relativeTimeInfo.utc_datetime)).getTime()
          const delta = (Date.now() - baseDeltaTime)
          const realTime = relativeTime + delta

          return realTime
        }
        moment.tz.setDefault('America/Recife')

        console.log('MOMENT REAL TIME SETTED', moment().format('YYYY-mm-DD HH:mm:ss.SSSSZ'))
        resolve(moment)
      } catch (err) {
        console.log('MOMENT WARNING LOCAL TIME SETTED:', err)
        resolve(moment)
      }
    })
  }

  async getMoment () {
    return this._promisse
  }
}()
