export default {
  user: {
    name: ''
  },
  institution: null,
  token: null,
  permissions: [],
  loggedIn: false,
  temporarySession: {
    user: {
      name: ''
    },
    token: null,
    permissions: []
  }
}
