import Vue from 'vue'
import FieryVue from 'fiery-vue'
import VueSync from 'vue-sync'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueTelInput from 'vue-tel-input'
import VueZoomer from 'vue-zoomer'
import { createSocket } from './socketmessage'
import './youtubeEmbed'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'vue-loaders/dist/vue-loaders.css'
import 'vue-tel-input/dist/vue-tel-input.css'
import '../styles/index.css'
import './moment'

createSocket()

Vue.use(VueSync)
Vue.use(PerfectScrollbar)
Vue.use(FieryVue)
Vue.use(VueZoomer)
Vue.component('vue-tel-input', VueTelInput)
