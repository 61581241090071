import { getField, updateField } from 'vuex-map-fields'
import dynamicComponentsApi from '../../../api/dynamicComponents'

export const state = () => ({
  reports: []
})

export const getters = {
  getField
}

export const actions = {
  async list ({ commit, dispatch, state, rootState }) {
    const reports = await dynamicComponentsApi.getComponentsMetaData({
      type: 'Agenda Diária'
    })
    commit('setReports', reports)
  }
}

export const mutations = {
  updateField,
  setReports (state, data) {
    state.reports = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
