import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(Router)

var router = new Router({
  mode: process.env.VUE_APP_CORDOVA ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  console.log(`FROM: ${from.path} TO: ${to.path}`)
  if (!store.getters['auth/authenticated']) {
    if (to.path === '/') {
      if (process.env.VUE_APP_CORDOVA) {
        console.log('ué')
        next('/login')
      } else {
        next('/dash')
      }
    } else if (to.path.match(/password\/reset/g) || to.path === '/login') {
      next()
    } else if (!to.path.match(/login/g) && !to.path.match(/register/g)) {
      // store.commit(app/setUrlRequestedPath, from.path)
      if (to.path.endsWith('/')) {
        next(`${to.path}login`)
      } else {
        next(`${to.path}/login`)
      }
    // else if (to.path === '/login' && store.getters['auth/authenticated']) {
    //   next('/login')
    // }
    } else {
      next()
    }
  } else {
    var institution = store.getters['auth/institution']
    var path = (' ' + to.path).slice(1)
    var page = path.replace(`/${institution}`, '')
    if (page === '' || page === '/students' || page === '/professors' || page === '/parents' || page === '/settings') {
      store.commit('app/hasFab', true)
    } else {
      store.commit('app/hasFab', false)
    }

    if (process.env.VUE_APP_CORDOVA) {
      next()
    } else {
      if (to.path === '/') {
        next(`/${store.getters['auth/institution']}`)
      } else {
        if (to.path.match(/login/g)) {
          next(`/${store.getters['auth/institution']}`)
        }
        // store.commit(app/setUrlRequestedPath, to.path)
        next()
      }
    }
  }
})

export default router
