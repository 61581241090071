import { getField, updateField } from 'vuex-map-fields'
import responsibleApi from '../../../api/responsible'

export const state = () => ({
  childrenList: [],
  responsibleList: []
})

export const getters = {
  getField,
  getResponsibleList (state) {
    return state.responsibleList
  }
}

export const actions = {
  async list ({ commit, dispatch, state, rootState }) {
    const responsibleList = await responsibleApi.list()
    commit('update', responsibleList)
    return responsibleList
  },
  async verifyChild ({ commit, dispatch, state, rootState }, matricula) {
    try {
      const child = await responsibleApi.verifyStudentRegistration(matricula)
      return child
    } catch (err) {
      console.error(err)
      throw new Error(err.response.data.message)
    }
  },
  async getChildren ({ commit, state }, user) {
    if (state.childrenList.length === 0) {
      const childrenList = await responsibleApi.getChildren(user.me.cpf)
      commit('setChildrenList', childrenList)
    }
  },
  /**
   * @description Get student parents
   * @param {String} registration Student registration
   */
  async get ({ commit }, registration) {
    const parents = await responsibleApi.getParents(registration)
    return parents
  }
}

export const mutations = {
  updateField,
  update (state, data) {
    state.responsibleList = data
  },
  setChildrenList (state, data) {
    state.childrenList = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
