import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

import { getField, updateField } from 'vuex-map-fields'

import VuexPersistence from 'vuex-persist'

const vuexPersist = new VuexPersistence({
  key: `${window.location.pathname.split('/')[1]}_vuex`,
  storage: window.localStorage,
  modules: [
    'auth'
  ]
})

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  getters: {
    getField
  },
  mutations: {
    updateField
  },
  modules: modules,
  plugins: [vuexPersist.plugin]
})
