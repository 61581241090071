/* eslint-disable */
import firebase from '../configs/firebase'

export default new class Firebase {
  constructor () {    
    this.firestore = firebase.firestore()
    this.storage = firebase.storage()
  }

  async uploadFile (file) {
    return new Promise(async (resolve, reject) => {
      const fileStr = await file.path
      const fileExtension = file.name.split('.').slice(1).join('.')
      const storageRef = this.storage.ref(`/quoti-posts-images/${MD5(fileStr)}.${fileExtension}`)

      storageRef.getDownloadURL()
        .then((downloadURL) => {
          resolve(downloadURL)
        })
        .catch((err) => {
          console.error(err)
          storageRef.putString(fileStr, 'data_url').then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL()
            resolve(url)
          })
        })
    })
  }
}()
