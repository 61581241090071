import { getField, updateField } from 'vuex-map-fields'
import PostsApi from '../../../api/posts'
import throttles from '../../../throttles'

export const state = () => ({
  list: [],
  enqueuedResumeRecipients: [],
  models: [],
  categories: [],
  emptyResponse: false
})

export const getters = {
  getField,
  getPostsFields (state) {
    return state.list
  }
}

export const actions = {

  async delete ({ commit }, post) {
    try {
      await PostsApi.delete(post)
      commit('postDelete', post)
    } catch (err) {
      console.error(err)
    }
  },

  async allow ({ commit }, post) {
    try {
      const result = await PostsApi.allow(post)
      post.type = 'post'
      return result
    } catch (err) {
      console.error(err)
    }
  },

  async deny ({ commit }, post) {
    try {
      const result = await PostsApi.deny(post)
      commit('postDelete', post)
      return result
    } catch (err) {
      console.error(err)
    }
  },

  async read ({ commit }, postId) {
    try {
      commit('read/addTask', {
        post_id: postId
      }, { root: true })

      throttles.readAllQueuedThrottled()
    } catch (err) {
      console.error(err)
    }
  },

  async resumeRecipients ({ commit, state }, posts) {
    const idsRecipients = posts.map(p => p.id).join(',')
    if (posts && posts.length && state.enqueuedResumeRecipients.indexOf(idsRecipients) === -1) {
      const recipientsResumes = await PostsApi.getResumeRecipients(posts)

      commit('postUpdateRecipients', recipientsResumes)
      commit('pushRecipients', idsRecipients)
    }
  },

  async create ({ commit, dispatch }, data) {
    const res = await PostsApi.createPost(data)
    const post = res.data[0]

    post.assocs_resume = 'Carregando...'
    post.assocs_resume_title = 'Carregando...'

    commit('postAddAtStart', post)
    await dispatch('resumeAssocs', [post])
  },

  async edit ({ commit, dispatch }, data) {
    await PostsApi.editPost(data)

    commit('edit', data)
    await dispatch('resumeAssocs', [data])
  },

  async listModels ({ commit }) {
    const res = await PostsApi.getModels()
    commit('models', res.data)
  },

  async listCategories ({ commit }) {
    const res = await PostsApi.getCategories()
    commit('categories', res.data)
  },

  /**
   * @description Resumes posts assocs to a string
   * @param {*} ids Posts ids
   */
  async resumeAssocs ({ commit }, posts) {
    const assocs = await PostsApi.getGroupsAssocs(posts.map(post => post.id))
    const resumes = {}

    assocs.forEach(assoc => {
      let row = null

      if (assoc.type === 'group') {
        row = `${resumes[assoc.post_id] ? '; ' : ''}${assoc.users_types_names_plural.map(type => type).join(', ')} do grupo ${assoc.group_name}`
      } else if (assoc.type === 'user') {
        const selfBond = assoc.bonds.find(b => b.slug === 'self')
        const bonds = assoc.bonds.filter(b => b.slug !== 'self')

        if (selfBond) {
          row = `${resumes[assoc.post_id] ? '; ' : ''}Ao usuário ${assoc.user_name}`

          if (bonds.length) {
            row += `e os seus ${bonds.map(b => b.name_plural).join(', ')}`
          }
        } else {
          row = `${resumes[assoc.post_id] ? '; ' : ''}Aos ${bonds.map(b => b.name_plural).join(', ')} do usuário ${assoc.user_name}`
        }
      }

      if (resumes[assoc.post_id] && row) {
        resumes[assoc.post_id].title += row
        resumes[assoc.post_id].length++

        if (resumes[assoc.post_id].length < 3) {
          resumes[assoc.post_id].content += row
        } else if (resumes[assoc.post_id].length === 3) {
          resumes[assoc.post_id].content += '...'
        }
      } else if (row) {
        resumes[assoc.post_id] = {
          title: row,
          content: row,
          length: 1
        }
      }
    })

    commit('assocsResumes', resumes)
  }
}

export const mutations = {
  updateField,

  postsClear (state) {
    state.list = []
  },

  pushRecipients (state, data) {
    state.enqueuedResumeRecipients.push(data)
  },

  postUpdate (state, data) {
    if (data && data.length) {
      for (const post of data) {
        if (typeof state.list.find(p => p.id === post.id) === typeof undefined) {
          state.list.push(post)
        }
      // state.list.push(...data)
      }
      // state.list.forEach(r => {
      //   if (!r.resume_recipient) {
      //     r.resume_recipient = 'Carregando...'
      //   }
      // })
      data.map((r) => {
        if (!r.resume_recipient) {
          r.resume_recipient = 'Carregando...'
          r.assocs_resume = 'Carregando...'
          r.assocs_resume_title = 'Carregando...'
        }
        return r
      })
    }
  },
  postUpdateRecipients (state, data) {
    let mapList = {}

    state.list.map((post) => {
      mapList[post.id] = post
    })

    data.map((recipient) => {
      if (mapList[recipient.id]) {
        if (recipient.name_group_by_user && recipient.name_group_by_user.length > 0) {
          mapList[recipient.id].resume_recipient = 'Alunos da(s) turma(s): ' + recipient.name_group_by_user
          if (recipient.user_names && recipient.user_names.length > 0 && recipient.user_names.split(',').length < 2) {
            mapList[recipient.id].resume_recipient += ('  ' + recipient.user_names)
          }
        } else if (recipient.name_group_direct && recipient.name_group_direct.length > 0) {
          mapList[recipient.id].resume_recipient = 'Enviado para a(s) turma(s): ' + recipient.name_group_direct
        } else {
          mapList[recipient.id].resume_recipient = recipient.cod
        }
      }
    })
  },
  postDelete (state, postDeleted) {
    state.list = state.list.filter(post => post.id !== postDeleted.id)
  },
  postAddAtStart (state, post) {
    state.list.unshift(post)
  },

  edit (state, data) {
    const post = state.list.find(post => post.id === data.id)
    Object.assign(post, data)
  },

  models (state, data) {
    state.models = data
  },

  categories (state, data) {
    state.categories = data
  },

  assocsResumes (state, resumes) {
    state.list.forEach(post => {
      if (resumes[post.id]) {
        post.assocs_resume = resumes[post.id].content
        post.assocs_resume_title = resumes[post.id].title
      } else if (post.assocs_resume === 'Carregando...') {
        post.assocs_resume = ''
        post.assocs_resume_title = ''
      }
    })
  },

  emptyResponse (state, boolean) {
    state.emptyResponse = boolean
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
