import { createModuleLogger } from '../../../configs/winston'

const l = createModuleLogger('store/read')

export const state = () => ({
  queue: []
})

export const getters = {
  getQueue (state) {
    return state.queue
  }
}

export const actions = {
}

export const mutations = {
  addTask (state, task) {
    const taskIndex = state.queue.findIndex(t => t.post_id === task.post_id)

    if (taskIndex === -1) {
      state.queue.push(task)
    }
  },
  removeTasks (state, tasks) {
    const postsIds = tasks.map(t => t.post_id)

    l.info(`Queue has ${state.queue.length} task(s) before remove`)
    l.info(`Will remove ${tasks.length} task(s)`)

    state.queue = state.queue.filter(t => !postsIds.includes(t.post_id))

    l.info(`Tasks removed, queue has ${state.queue.length} task(s)`)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
