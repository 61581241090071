import { updateField } from 'vuex-map-fields'

export default {
  updateField,
  setMe (state, data) {
    state.me = data
  },
  setPosts (state, data) {
    state.posts = data
  },
  updateProfileImage (state, data) {
    Object.assign(state.me, {
      profile_image_url: data.imageUrl
    })
  }
}
