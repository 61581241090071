import UserApi from '../../../api/user'
import authApi from '../../../api/auth'
import router from '../../../router'
import throttles from '../../../throttles'
import notification from '../../../notifications'
// import store from '../../index'

export default {
  async logout ({
    commit,
    state
  }) {
    try {
      // Remove device to not notify
      UserApi.removeDevice()

      // Read all pending posts to read
      await throttles.readAllQueuedThrottled.cancel()
      await throttles.readAllQueuedThrottled()

      commit('logout')
      commit('cleanAuth')
      window.localStorage.clear()

      // Remove device to not notify
      const currentToken = notification.getToken()
      UserApi.removeDevice(currentToken)

      // router.push(`${store.getters['auth/institution']}/login`)
      // const institution = router.currentRoute.params.institution || process.env.VUE_APP_CORDOVA
      router.push({
        name: 'login'
      })

      /**
       * Fast way to clear store instance...
       */
      setTimeout(() => {
        console.log('For now this works...')
        window.location.reload()
      }, 400)
    } catch (err) {
      console.error(err)
    }
  },
  async verifyUser ({
    commit,
    dispatch
  }, data) {
    try {
      let res = await UserApi.verifyUser(data)

      if (res.data.lenght === 0) return null

      if (res.data.hasOwnProperty('registered')) {
        return res.data
      } else {
        console.error('Retorno inesperado: ', res.data[0])
        return false
      }
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async verifyLastName ({
    commit,
    dispatch
  }, data) {
    try {
      let res = await UserApi.verifyLastName(data)

      if (res.data.hasOwnProperty('equal') && res.data.hasOwnProperty('email')) {
        return res.data
      } else if (res.data.hasOwnProperty('equal')) {
        return res.data
      } else {
        console.error('Retorno inesperado: ', res.data)
        return { equal: false, error: true }
      }
    } catch (err) {
      console.error(err)
    }
  },
  async login ({
    commit,
    dispatch
  }, data) {
    try {
      let res = await UserApi.login(data)

      if (res.data.hasOwnProperty('token') && res.data.hasOwnProperty('permissions')) {
        const token = res.data.token
        const permissions = res.data.permissions

        commit('setSuccess', {
          token,
          institution: data.institution,
          permissions
        })

        return true
      } else {
        console.error('Retorno inesperado: ', res.data)
        return res.data
      }
    } catch (err) {
      console.error(err)
    }
    return false
  },
  async logAsUser ({
    commit
  }, data) {
    const token = await authApi.getToken(data.userid)
    const permissions = await UserApi.getUserPermissions(data.userid)

    commit('setTemporarySession', {
      token,
      permissions
    })
  }
}
