import auth from './auth'
import appSettings from './app_settings/'
import posts from './posts'
import groups from './groups'
import responsible from './responsible'
import chat from './chat'
import users from './users'
import user from './user'
import reports from './reports'
import dynamicComponents from './dynamicComponents/dynamicComponents'
import diary from './diary'
import post from './post'
import feed from './feed'
import search from './search'
import organization from './organization'
import read from './read'

export default {
  app: appSettings,
  auth,
  posts,
  groups,
  responsible,
  chat,
  organization,
  post,
  users,
  user,
  reports,
  diary,
  feed,
  dynamicComponents,
  search,
  read
}
