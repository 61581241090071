import store from '../store/'
export default class {
  static validatePermissions (permissions) {
    const userPermissions = store.getters['auth/permissions']

    for (let permission of permissions) {
      if (userPermissions.findIndex(p => p.name === permission) === -1) {
        return false
      }
    }

    return true
  }

  static validateSomePermission (permissions) {
    const userPermissions = store.getters['auth/permissions']

    if (userPermissions) {
      for (let permission of permissions) {
        if (userPermissions.findIndex(p => p.name === permission) !== -1) {
          return true
        }
      }
    }

    return false
  }
}
