import { getField } from 'vuex-map-fields'

const getActualSessionState = function (state) {
  let actualSessionState
  const searchParams = new URLSearchParams(window.location.search)

  if (searchParams.has('temp_token') && searchParams.get('temp_token') === 'true') {
    actualSessionState = state.temporarySession
  } else {
    actualSessionState = state
  }

  return actualSessionState
}

export default {
  getField,

  institution (state) {
    return state.institution
  },
  token (state) {
    const actualSessionState = getActualSessionState(state)

    return actualSessionState.token
  },
  authenticated (state) {
    // if (process.env.NODE_ENV === 'development') {
    //   return true
    // } else {
    //   return state.loggedIn
    // }
    // return state.loggedIn
    return !!state.token
  },
  userAuthenticated (state) {
    const actualSessionState = getActualSessionState(state)

    return actualSessionState.user
  },
  permissions (state) {
    const actualSessionState = getActualSessionState(state)

    return actualSessionState.permissions
  }
}
