import Feed from '../pages/Feed.vue'
import Login from '../pages/Login.vue'
import NotFound from '../pages/NotFound.vue'
import Deny from '../pages/Deny.vue'
import Error from '../pages/Error.vue'
/**
 *
 * Every one below can be lazy loaded... needed to fix unexpected token with import by adding SplitCunks that may bad configured in this project.
 *
 */
import Contact from '../pages/Contact.vue'
import Groups from '../pages/Groups.vue'
import PasswordRecover from '../pages/RecoverPassword.vue'
// import Parents from '../pages/Parents.vue'
// import Students from '../pages/Students.vue'
// import Professors from '../pages/Professors.vue'
import Users from '../pages/Users.vue'
import dynamicComponentsTestPage from '../pages/DynamicComponentsTestPage.vue'
import UserProfile from '../pages/UserProfile.vue'
import Reports from '../pages/Reports.vue'
import Diary from '../pages/Diary.vue'
import DiaryIDB from '../pages/DiaryIDB.vue'
import Presence from '../pages/Presence.vue'
import dynamic from '../pages/Dynamic.vue'
import Iframe from '../pages/Iframe.vue'
import CategoryFeed from '../pages/CategoryFeed.vue'
import CategoryFeedByChild from '../pages/CategoryFeedByChild.vue'
import Post from '../pages/Post.vue'
import Config from '../pages/Settings.vue'
import Ouvidoria from /* webpackPrefetch: true */ '../pages/Chat.vue'
import Grades from '../pages/Grades.vue'
import ChatConfig from '../pages/ChatConfig.vue'
import dynamicComponents from '../pages/DynamicComponents.vue'
import Permsissions from '../pages/Permsissions'
import MenuItens from '../pages/MenuItens'
import UserTypes from '../pages/UserTypes'
import UserProfiles from '../pages/UserProfiles'
// eslint-disable-next-line
import Catraca from '../pages/Catraca.vue'
import Dash from '../pages/SchoolList.vue'
// import Responsible from '../pages/Responsible.vue'
import Updates from '../pages/Updates'

const pathResolve = (path) => {
  if (process.env.VUE_APP_CORDOVA) {
    return `/${path}`
  } else {
    return `/:institution/${path}`
  }
}

export default [
  {
    meta: {
      public: true
    },
    path: '/dash/*',
    name: 'dash',
    component: Dash
  },
  {
    meta: {
      public: true
    },
    path: pathResolve('login'),
    name: 'login',
    component: Login
  },
  {
    path: pathResolve(''),
    name: 'home',
    component: Feed
  },
  /**
   * É necessário para a bottom navigation ficar com background diferente..
   */
  {
    path: pathResolve('/feed'),
    name: 'feed',
    component: Feed
  },
  {
    path: pathResolve('grades'),
    name: 'grades',
    component: Grades
  },
  {
    path: pathResolve('catraca'),
    name: 'catraca',
    component: Catraca
  },
  // {
  //   meta: {
  //     public: true
  //   },
  //   path: '/index.html',
  //   component: Login,
  //   alias: '/login/'
  // },
  {
    path: pathResolve('categoryfeed'),
    name: 'categoryFeed',
    component: CategoryFeed
  },

  {
    path: pathResolve('categoryfeedbychild'),
    name: 'categoryFeedbychild',
    component: CategoryFeedByChild
  },
  {
    meta: {
      public: true
    },
    path: pathResolve('password/reset/:hash'),
    name: 'passwordRecover',
    component: PasswordRecover
  },
  {
    path: pathResolve('settings'),
    name: 'settings_applications',
    component: Config
  },
  {
    path: pathResolve('chatconfig'),
    name: 'chat_config',
    component: ChatConfig
  },
  /* {
    path: '',
    redirect: {
      path: '/noticias'
    }
  },
  {
    path: '/noticias',
    name: 'home',
    component: Feed
  }, */
  {
    path: pathResolve('post/:postId'),
    name: 'PostPage',
    component: Post
  },
  {
    path: pathResolve('users'),
    name: 'users',
    props: true,
    component: Users
  },
  {
    path: pathResolve('groups'),
    name: 'groups',
    component: Groups
  },
  {
    path: pathResolve('presence'),
    name: 'presence',
    component: Presence
  },
  // {
  //   path: pathResolve('users?types=%5B"parent"%5D'),
  //   name: 'parent',
  //   component: Users
  // },
  // {
  //   path: pathResolve('users?types=%5B"aluno"%5D'),
  //   name: 'students',
  //   component: Users
  // },
  // {
  //   path: pathResolve('users?types=%5B"prof_supervised"%5D'),
  //   name: 'professors',
  //   component: Users
  // },
  {
    path: pathResolve('diaryidb'),
    name: 'diaryidb',
    component: DiaryIDB
  },
  {
    path: pathResolve('diary'),
    name: 'diary',
    component: Diary
  },
  {
    path: pathResolve('ouvidoria'),
    name: 'chat',
    component: Ouvidoria
  },
  {
    path: pathResolve('iframe'),
    name: 'iframe',
    component: Iframe
  },
  {
    path: '*',
    meta: {
      public: true
    },
    redirect: {
      path: pathResolve('/404')
    }
  },
  {
    path: pathResolve('/404'),
    meta: {
      public: true
    },
    name: 'NotFound',
    component: NotFound
  },
  {
    path: pathResolve('/403'),
    meta: {
      public: true
    },
    name: 'AccessDenied',
    component: Deny
  },
  {
    path: pathResolve('/500'),
    meta: {
      public: true
    },
    name: 'ServerError',
    component: Error
  },
  {
    path: pathResolve('/dynamicComponents/test/page'),
    name: 'dynamicComponentsTestPage',
    component: dynamicComponentsTestPage
  },
  {
    path: pathResolve('user/:userId'),
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: pathResolve('reports'),
    name: 'reports',
    component: Reports
  },
  /**
   *
   * Apenas para não termos erros o tempo todo no template...
   *
   */
  {
    path: pathResolve('listitens'),
    name: 'listitens'
  },
  {
    path: pathResolve('developer'),
    name: 'developer'
  },
  {
    path: pathResolve('configuracoes'),
    name: 'configuracoes'
  },
  {
    path: pathResolve('configuracao'),
    name: 'configuracao'
  },
  {
    path: pathResolve('contact'),
    name: 'contact',
    component: Contact
  },
  {
    path: pathResolve('dynamiccomponents'),
    name: 'dynamic_components',
    component: dynamicComponents
  },
  {
    path: pathResolve('permissions'),
    name: 'permissions',
    component: Permsissions
  },
  {
    path: pathResolve('menuitens'),
    name: 'menuitens',
    component: MenuItens
  },
  {
    path: pathResolve('usertypes'),
    name: 'usertypes',
    component: UserTypes
  },
  {
    path: pathResolve('userprofiles'),
    name: 'userprofiles',
    component: UserProfiles
  },
  {
    path: pathResolve('dynamic/:dynamicComponentPath'),
    name: 'dynamic',
    component: dynamic
  },
  {
    path: pathResolve('updates'),
    name: 'updates',
    component: Updates
  }
]
