import { getField, updateField } from 'vuex-map-fields'
import PostsApi from '../../../api/posts'

/**
 *
 *
 * This store will be used only to save current post editing on localstorage...
 *
 *
 */
export const state = () => ({
  searchDrawer: false,
  filterEnsino: '',
  onlyPending: false,
  selectedUsers: [],
  searchText: ''
})

export const getters = {
  getField,
  getSearchDrawer (state) {
    return state.searchDrawer
  }
}

export const actions = {

  toggleSearchDrawer ({
    commit,
    state
  }) {
    commit('setSearchDrawer', !state.searchDrawer)
  },
  async listPosts ({ commit, dispatch, state, rootState }) {
    // Posts Clear realmente tem que ser chamado duas vezes... limpa logo e depois limpa brevemente antes de exibir o resultado.
    commit('posts/postsClear', [], { root: true })
    const queryParams = {
      filterEnsino: state.filterEnsino,
      onlyPending: state.onlyPending,
      search: rootState.search.text
    }
    if (state.selectedUsers && state.selectedUsers.length > 0) {
      queryParams.selectedUsers = state.selectedUsers.map(user => user.id).join(',')
    }
    const list = await PostsApi.list(queryParams)
    commit('posts/postsClear', [], { root: true })
    if (list && list.length > 0) {
      commit('posts/postUpdate', list, { root: true })
      await dispatch('posts/resumeRecipients', list, { root: true })
      await dispatch('posts/resumeAssocs', list, { root: true })
    }
  },

  async listMorePosts ({ commit, dispatch, state, rootState }) {
    var lastPost = rootState.posts.list[rootState.posts.list.length - 1]
    let lastItem = lastPost ? `${lastPost.id}` : ''
    const queryParams = {
      filterEnsino: state.filterEnsino,
      onlyPending: state.onlyPending,
      search: state.searchText,
      last: lastItem
    }
    if (state.selectedUsers && state.selectedUsers.length > 0) {
      queryParams.selectedUsers = state.selectedUsers.map(user => user.id).join(',')
    }
    const list = await PostsApi.list(queryParams)
    if (list && list.length > 0) {
      commit('posts/emptyResponse', false, { root: true })
      commit('posts/postUpdate', list, { root: true })
      dispatch('posts/resumeRecipients', list, { root: true })
      dispatch('posts/resumeAssocs', list, { root: true })
    } else {
      commit('posts/emptyResponse', true, { root: true })
    }
  }

}

export const mutations = {
  updateField,
  setSearchDrawer (state, bool) {
    state.searchDrawer = bool
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
