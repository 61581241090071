import store from '../store'
import postsApi from '../api/posts'
import _ from 'lodash'
import { createModuleLogger } from '../configs/winston'

const l = createModuleLogger('throttles')

export default new class {
  constructor () {
    this.readAllQueuedThrottled = _.throttle(this._readAllQueued, 5000)
  }

  async _readAllQueued () {
    l.info('Will read all queued tasks')

    const nextTasks = [
      ...store.getters['read/getQueue']
    ]

    if (nextTasks.length > 0) {
      l.info(`There're ${nextTasks.length} task(s) to read`)

      try {
        await postsApi.read(nextTasks)

        l.info('Remove readed tasks')

        store.commit('read/removeTasks', nextTasks)

        // If queue is empty, cancel delayed
        const queue = store.getters['read/getQueue']
        if (queue.length === 0) {
          l.info('Queue is empty, will cancel all delayed functions')

          this.readAllQueuedThrottled.cancel()

          // In case cancel executes after a push
          this.readAllQueuedThrottled()
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}()
